import React from "react";
import "./style.less";
import { Row, Col } from "antd";
import { Link } from "@reach/router";
import ImageOverlap from "../ImageOverlap";
import ReactHtmlParser from "react-html-parser";

class PhotoIntro extends React.Component {
  render() {
    return (
      <Row className="photo-intro">
        <Col xs={{ span: 24 }} md={{ span: 14 }} lg={{ span: 10 }}>
          <h2>About us</h2>
          <p>{ReactHtmlParser(this.props.blurb)}</p>
          <Link className="arrow-link" to="/about/">
            Get to know us
          </Link>
        </Col>
        <Col xs={{ span: 24 }}>
          <ImageOverlap
            side={this.props.images.align_image}
            portrait={this.props.images.portrait_image}
            landscape={this.props.images.landscape_image}
          />
        </Col>
      </Row>
    );
  }
}

export default PhotoIntro;
