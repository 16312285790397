import React from 'react'
import './style.less';
import {Row, Col} from "antd";
import WorkListItem from "../../components/WorkListItem";
import Link from "../../components/Link";
import {graphql, StaticQuery} from "gatsby";

class FeaturedWork extends React.Component {

    render(){

        return (
            <div className="featured-work">
                <Row>
                    <Col xs={{span: 24}}>
                        <h2 className="huge">Work</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 24}} md={{span:3}} >
                        <Link to="/work/" className="arrow-link">Watch our work</Link>
                    </Col>
                    <Col xs={{span: 24}} md={{span:21}}>
                        <Row>
                            <Col xs={{span: 24}}>
                                <div className="featured">
                                    <WorkListItem
                                        work={this.props.work[0].node}
                                    />
                                </div>
                            </Col>
                            <Col xs={{span: 24}} md={{span:10}}>
                                <WorkListItem
                                    work={this.props.work[1].node}
                                />
                            </Col>
                            <Col xs={{span: 24}} md={{span:10, offset: 4}}>
                                <WorkListItem
                                    work={this.props.work[2].node}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql` query featuredWorkListQuery {
            allWordpressWpCaseStudy(limit: 3, sort: { order: DESC, fields: [date] }){
              edges {
                node {
                  wordpress_id
                  id
                  date
                  slug
                  title
                  acf {
                    featured_image {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 350, maxHeight:215, quality: 90, cropFocus: CENTER) {
                            ... GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                    listing_blurb
                  }
                }
              }
            }
           }
        `}
        render={(data) => (
            <FeaturedWork work={data.allWordpressWpCaseStudy.edges} />
        )}
    />
)